<template>
  <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="companySubaccount-container">
    <div class="title-main">
      <p> <span @click="() => { $router.push('/customer/company-shop-list') }">店铺管理</span>  / <span class="normal">子账号管理</span></p>
      <div>
        <el-button v-if="isAssign == 0" class="common-screen-btn" type="primary" @click="addEdit()">新建子账号</el-button>
        <!-- <div v-if="isAssign == 0" class="common-upload-container" style="margin: 0;display: inline-block">
          <el-button class="common-screen-btn" type="primary" :loading="btnLoading">批量上传分配客服</el-button>
          <input v-if="!btnLoading" class="common-upload-input" @input="(e) => {serviceUpload(e)}" type="file"/>
        </div>
        <el-button v-if="isAssign == 0" class="common-screen-btn" type="text" @click="() =>downLoad()">子账号分配客服模板</el-button> -->
        <el-button class="common-screen-btn" type="primary" @click="() => {downLoad($route.query.fileUrl)}">下载店铺交接文档</el-button>
      </div>
    </div>
    <!-- <div class="screen-main">
      <span>订单编号:</span>
      <el-select class="screen-select" v-model="params.orderId" placeholder="请选择" @change="commonFun">
        <el-option v-for="item in shopOrder" :key="item.id" :label="item.orderNo + ' ' + item.startTime + '-' + item.endTime" :value="item.id"></el-option>
      </el-select>
    </div> -->
    <div class="companySubaccount-detail">
      <img
        class="detail-img"
        v-if="this.$route.query.shopLogo"
        :src="this.$route.query.shopLogo"
        alt="icon"
      />
      <span class="user-icon" v-else><i class="el-icon-user iconPeople"></i></span>
      <div>
        <div class="detail-title">店铺名称: {{ $route.query.shopName || "--" }}</div>
        <!-- <span class="detail-item">所属公司: {{ $route.query.companyName || "--" }}</span> -->
        <span class="detail-item">所属平台: {{ $route.query.platformName || "--" }}</span>
        <span class="detail-item">所属类目: {{ $route.query.categoryName || "--" }}</span>
        <span class="detail-item">所属客户: {{ $route.query.customerName || "--" }}</span>
      </div>
    </div>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>子账号名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.name"
        ></el-input>
      </div>
      <div class="common-input-container">
        <el-date-picker
          v-model="params.datePicker"
          type="daterange"
          range-separator="~"
          @change="commonFun"
          :clearable="false"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <el-button class="common-screen-btn" type="primary" @click="() => subaccountListNew()"
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
    </div>
    <!-- <el-button class="common-screen-btn" type="primary" @click="addEdit()">新建子账号</el-button>

    <div class="common-upload-container" style="margin: 0">
      <el-button class="common-screen-btn" type="primary" :loading="btnLoading"
        >批量上传分配客服</el-button
      >
      <input
        v-if="!btnLoading"
        class="common-upload-input"
        @input="
          (e) => {
            serviceUpload(e);
          }
        "
        type="file"
      />
    </div>
    <el-button
      class="common-screen-btn"
      type="text"
      @click="
        () =>
          downLoad()
      "
      >子账号分配客服模板</el-button
    > -->
    <div class="table-main">
      <el-table class="common-table" :data="tableData">
        <el-table-column
          prop="subaccountName"
          label="子账号名称"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="subaccountPassword"
          label="子账号密码"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="管理员手机号"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="serviceName"
          label="指派客服"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column label="服务时间" width="200px">
          <template slot-scope="scope">
            <span>{{
              scope.row.startTime && scope.row.endTime
                ? scope.row.startTime + " ~ " + scope.row.endTime
                : "--"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="note"
          label="备注"
        >
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.note" class="item" effect="dark" :content="scope.row.note" placement="bottom">
              <div class="common-overflow-drop">{{scope.row.note}}</div>
            </el-tooltip>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createName"
          label="创建人"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          :formatter="tableColumn"
          label="创建时间"
        ></el-table-column>
        <el-table-column label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="
                () => {
                  $router.push({
                    path: '/customer/company-shop-list/subaccount-list/service',
                    query: {
                      shopid: $route.query.id,
                      subaccountName: scope.row.subaccountName,
                      shopName: $route.query.shopName,
                      companyName: $route.query.companyName,
                      companyId: $route.query.companyId,
                      departName: $route.query.departName,
                      categoryName: $route.query.categoryName,
                      platformName: $route.query.platformName,
                      customerName: $route.query.customerName,
                      startTime: $route.query.startTime,
                      endTime: $route.query.endTime,
                      shopLogo: $route.query.shopLogo,
                      fileUrl: $route.query.fileUrl,
                      subId: scope.row.id,
                      isAssign: $route.query.isAssign
                    },
                  });
                }"
              >客服分配情况</el-button>
            <el-button type="text" size="small" @click="() => addEdit(scope.row)">编辑</el-button>
            &nbsp;&nbsp;&nbsp;
            <el-popconfirm
              placement="top"
              title="确定删除吗？"
              @confirm="() => deleteSubaccount(scope.row.id)"
            >
              <el-button type="text" size="small" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      v-dialogDrag
      :title="!tableRowId ? '新建子账号' : '修改子账号'"
      :append-to-body="true"
      :visible.sync="centerDialogVisible"
      width="520px"
    >
      <div>
        <el-form :model="ruleFormAdd" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" >
          <el-form-item label="子账号名称" prop="subaccountName">
            <el-input
              class="dialog-input"
              placeholder="请填写子账号名称"
              v-model="ruleFormAdd.subaccountName"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="子账号密码" prop="subaccountPassword">
            <el-input
              class="dialog-input"
              placeholder="请填写子账号密码"
              v-model="ruleFormAdd.subaccountPassword"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="管理员手机号" prop="phone">
            <el-input
              class="dialog-input"
              placeholder="请填写管理员手机号"
              v-model="ruleFormAdd.phone"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="服务时间">
            <span>{{$route.query.startTime}}-{{$route.query.endTime}}</span>
            <!-- <el-date-picker
              v-model="ruleFormAdd.datePicker"
              class="dialog-input"
              type="daterange"
              :clearable="false"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            
            </el-date-picker> -->
             <!-- :picker-options="pickerOptions" -->
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              class="dialog-input"
              placeholder="请填写备注"
              v-model="ruleFormAdd.note"
              maxLength="500"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="centerDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="() => submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="指派客服"
      :append-to-body="true"
      :visible.sync="assignVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="assignForm"
          :rules="assignRules"
          ref="assignForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="服务时间" prop="datePicker">
            <el-date-picker
              class="dialog-input"
              v-model="assignForm.datePicker"
              type="daterange"
              range-separator="~"
              @change="commonFun"
              :clearable="false"
              :picker-options="pickerOptions"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="指派客服" prop="serviceIds">
            <el-select
              class="dialog-input"
              v-model="assignForm.serviceIds"
              filterable
              multiple
              placeholder="选择客服"
            >
              <el-option
                v-for="item in serviceList"
                :key="item.id + ''"
                :label="item.nickName"
                :value="item.id + ''"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="assignVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              submitAssign('assignForm');
            }"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  subaccountListNew,
  addEditSubaccount,
  assignService,
  serviceUpload,
  deleteSubaccount
} from "../../service/customer.js";
import { getRoleByType } from "../../service/common.js";
import { shopOrderList } from "../../service/manage.js"
import { tableColumn, DateTransform, Config } from "../../utils/index.js";
import { mapState } from "vuex"
export default {
  components: {},
  data() {
    return {
      loading: false,
      tableColumn,
      // BreadcrumbData: [
      //   { title: "首页", isLink: true, url: "/index" },
      //   { title: "营销", isLink: false },
      //   { title: "运营", isLink: false },
      //   { title: "店铺管理", isLink: true, url: "/customer/company-shop-list" },
      //   { title: "子账号管理", isLink: false },
      // ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      id: this.$route.query.id, // 店铺列表传值
      tableRowId: "", // 客户id用于编辑/查看
      centerDialogVisible: false, // 新增修改弹窗
      assignVisible: false, // 指派分公司弹窗
      comPeopleList: [],
      assignForm: {}, // 指派分公司form表单
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
      assignRules: {
        serviceIds: [{ required: true, message: "请选择客服", trigger: "change" }],
        datePicker: [{ required: true, message: "请选择服务时间", trigger: "change" }],
      },
      serviceList: [], // 获取客服下拉
      btnLoading: false,
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      ruleFormAdd: {
        //
        shopId: this.$route.query.id,
      },
      rules: {
        subaccountName: [
          { required: true, message: "请填写子账号名称", trigger: "change" },
        ],
        subaccountPassword: [
          { required: true, message: "请填写子账号密码", trigger: "change" },
        ],
        // datePicker: [{ required: true, message: "请选择服务时间", trigger: "change" }],
      },
      drawer: false, // 新增员工弹窗
      roleSelect: "",
      tableData: [],
      pickerOptions: {
        // 指派分公司的禁用时间范围
        disabledDate: (time) => {
          return (
            time.getTime() <
              new Date(this.$route.query.startTime).getTime() - 24 * 60 * 60 * 1000 ||
            time.getTime() > new Date(this.$route.query.endTime).getTime()
          );
        },
      },
      shopOrder: []
    };
  },
  computed: {
    ...mapState(["comId"])
  },
  created() {
    this.subaccountListNew();
    this.getRoleByType(4);
    this.getRoleByType(2);
  },
  
  methods: {
    async getShopOrder(id){ //店铺关联订单列表
      const { data } = await shopOrderList({shopId: id})
      this.params.orderId = data[0].id
      this.firstOrderId = data[0].id
      this.shopOrder = data
    },
    async deleteSubaccount(id) {
      await deleteSubaccount({}, id);
      this.$message.success("删除成功");
      this.subaccountListNew();
    },
    async serviceUpload(e) {
      this.btnLoading = true;
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (format != "xls" && format != "xlsx") {
        this.$message.error("仅支持xls、xlsx格式文件");
        e.target.value = "";
        this.btnLoading = false;
        return;
      }
      let resData = (await serviceUpload({ file: file })).data;
      this.btnLoading = false;
      e.target.value = "";
      this.subaccountListNew();
      if (resData) {
        this.$message.error(resData);
      } else {
        this.$message.success("上传成功");
      }
    },
    downLoad(url) {
      // 下载交接文档
      if (url) {
        window.location.href = url;
      } else {
        window.location.href = Config.api.url + `/excel/serviceSubExport?companyId=${this.$route.query.companyId}&shopId=${this.$route.query.id}`
      }
    },
    submitAssign(formName) {
      // 指派提交
      let assignForm = { ...this.assignForm };
      if (assignForm.datePicker && assignForm.datePicker.length) {
        assignForm.startTime = DateTransform(assignForm.datePicker[0]);
        assignForm.endTime = DateTransform(assignForm.datePicker[1]);
        delete assignForm.datePicker
      }
      if (assignForm.serviceIds && typeof assignForm.serviceIds == "object") {
        assignForm.serviceIds = assignForm.serviceIds.join(",");
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await assignService(assignForm);
          this.$message.success("指派成功");
          this.subaccountListNew();
          this.assignVisible = false;
        } else {
          return false;
        }
      });
    },
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.subaccountListNew();
    },
    handleSelectionChange(row) {
      console.log(row);
    },
    resetBtn() {
      this.params = {
        pageNum: 1,
        pageSize: 10,
      };
      this.subaccountListNew();
    },
    async getRoleByType(id) {
      // 获取客服下拉
      let resData = (await getRoleByType({ companyId: this.comId }, id)).data;
      if (id == 2) {
        this.comPeopleList = resData;
      } else if (id == 4) {
        this.serviceList = resData;
      }
    },
    submitForm(formName) {
      // 新增修改提交
      let tableRowId = this.tableRowId;
      let ruleFormAdd = { ...this.ruleFormAdd };
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (tableRowId) {
            ruleFormAdd.id = tableRowId;
          }
          // if (ruleFormAdd.datePicker && ruleFormAdd.datePicker.length) {
          //   ruleFormAdd.startTime = DateTransform(ruleFormAdd.datePicker[0]);
          //   ruleFormAdd.endTime = DateTransform(ruleFormAdd.datePicker[1]);
          // }
          ruleFormAdd.startTime = this.$route.query.startTime;
          ruleFormAdd.endTime = this.$route.query.endTime;
          let { code } = await addEditSubaccount(ruleFormAdd);
          if ( code != 200 ) { return }
          if (tableRowId) {
            this.$message.success("修改成功");
          } else {
            this.$message.success("添加成功");
          }
          this.subaccountListNew();
          this.centerDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.subaccountListNew();
    },
    async subaccountListNew() {
      // 子账号列表
      this.loading = true;
      let params = { ...this.params };
      if (params.datePicker && params.datePicker.length) {
        params.startTime = DateTransform(params.datePicker[0]);
        params.endTime = DateTransform(params.datePicker[1]);
        delete params.datePicker
      }
      let resData = (await subaccountListNew({ ...params, id: this.id })).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.records;
      this.loading = false;
    },
    assignService(row) {
      if (row) {
        this.assignForm = {
          subId: row.id,
          serviceIds: row.serviceId,
          datePicker:
            row.startTime && row.endTime
              ? [new Date(row.startTime), new Date(row.endTime)]
              : [
                  new Date(this.$route.query.startTime),
                  new Date(this.$route.query.endTime),
                ],
        };
        this.assignVisible = true;
      }
    },
    addEdit(row) {
      // 新增修改弹窗
      if (row) {
        this.tableRowId = row.id;
        this.ruleFormAdd = {
          shopId: this.$route.query.id,
          subaccountName: row.subaccountName,
          subaccountPassword: row.subaccountPassword,
          datePicker: [new Date(row.startTime), new Date(row.endTime)],
          note: row.note,
          id: row.id,
          phone: row.phone,
        };
      } else {
        this.tableRowId = "";
        this.ruleFormAdd = {
          shopId: this.$route.query.id,
          datePicker: [],
        };
        if (this.$refs["ruleForm"]) this.$refs["ruleForm"].resetFields();
      }
      this.centerDialogVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.companySubaccount-container {
  padding: 32px;
  text-align: left;
  .screen-main {
    height: 96px;
    box-sizing: border-box;
    border-bottom: 16px solid #eee;
    display: flex;
    align-items: center;
    padding: 0 32px;
    .screen-select {
      margin-left: 16px;
      width: 350px;
    }
  }
  .title-main {
    display: flex;
    align-items: center;
    padding: 0 32px;
    box-sizing: border-box;
    height: 72px;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
    p {
      font-size: 20px;
      color: #666;
      span {
        font-size: 20px;
        color: #666;
        cursor: pointer;
      }
      .normal {
        font-size: 24px;
        color: #101010;
        cursor: auto;
      }
    }
  }
  .common-table {
    margin: 0;
  }
  .table-main {
    padding: 0 32px 32px;
  }
  .common-screen-container {
    padding: 0 32px;
  }
  .iconPeople {
    width: 80px;
    height: 80px;
    color: #fff;
    background: #87d068;
    border-radius: 50%;
    font-size: 70px;
    line-height: 80px;
    text-align: center;
    margin-right: 16px;
  }
  .companySubaccount-detail {
    display: flex;
    padding: 32px 32px 16px;
    align-items: center;
    color: #000000a6;
    .detail-img {
      width: 82px;
      height: 82px;
      margin-right: 20px;
    }
    .detail-title {
      margin-bottom: 16px;
      font-size: 20px;
    }
    .detail-item {
      margin-right: 16px;
    }
  }
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
</style>
